import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';

export function getFormDefinition(vue) {
  return {
    id: {
      type: MDinput,
      props: {
        readonly: true
      }
    },
    name: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'common.gender',
        maxlength: 32,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    salutation: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'common.genderSalutation',
        maxlength: 32
      },
      rules: []
    },
    prefix: {
      type: MDinput,
      defaultValue: '',
      props: {
        caption: 'common.genderPrefix',
        maxlength: 32
      },
      rules: []
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  // const form = {
  //   ...item
  // };
  // return form;
  return item;
}
